<script setup>
const components = {
  block_slider: resolveComponent('BlockSlider'),
  block_text: resolveComponent('BlockText'),
  block_embed: resolveComponent('BlockEmbed'),
  block_links: resolveComponent('BlockLinks'),
  block_list: resolveComponent('BlockList'),
}

defineProps({
  items: {
    type: Array,
    required: true,
  },
})
</script>

<template>
  <div>
    <div v-for="item in items" :key="item.id">
      <component
        :is="components[item.collection]"
        v-if="
          components[item.collection] &&
          item.item.is_enabled !== false &&
          checkDateRange(item.item.date_published, item.item.date_unpublished)
        "
        :data="item.item"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
