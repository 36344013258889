export function checkDateRange(
  date_published: string,
  date_unpublished: string
) {
  const from = new Date(date_published)

  const to = new Date(date_unpublished)
  return (
    (date_published === null || isNaN(from.getTime()) || from < new Date()) &&
    (date_unpublished === null || isNaN(to.getTime()) || to > new Date())
  )
}
