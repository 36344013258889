<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})
const { t, locale } = useI18n()
const localePath = useLocalePath()
const config = useRuntimeConfig()

// Helper function to get the current locale from a collection of translations
function getCurrentLocale(translations, fallback = true) {
  return (
    translations.find(
      (t) =>
        t.languages_code === locale.value ||
        t.languages_code.code === locale.value // needed because in some cases the languges_code is an object
    ) || (fallback ? translations[0] : undefined)
  )
}

// Helper function to get a random color
let colorIndex = 0
function nextColor() {
  const colors = [
    { color_text: '#CD00CD', color_background: '#CCD1C4' },
    { color_text: '#FFFF00', color_background: '#828F8F' },
    { color_text: '#3387FF', color_background: '#304244' },
    { color_text: '#FF1C00', color_background: '#E6F2EA' },
    { color_text: '#3CFF00', color_background: '#977631' },
    { color_text: '#F59C00', color_background: '#356645' },
  ]

  // the following code makes sure that the next color is not the same as the last color
  let nextColorIndex
  do {
    nextColorIndex = Math.floor(Math.random() * colors.length)
  } while (colorIndex === nextColorIndex)
  colorIndex = nextColorIndex
  return colors[colorIndex]
}

// Fetch the data and convert it to a format that the BlockSlider or BlockLinks component can render
// has to be done with useAsyncData because of possible randomization of items
const { data: convertedData } = await useAsyncData(
  `BlockList-${props.data.id}`,
  async function () {
    let items = props.data.items || []
    if (props.data.mode === 'collection' && props.data.collection) {
      if (props.data.collection === 'news') {
        const newsStore = useNewsStore()
        await callOnce('news-init', newsStore.init)
        items = newsStore.news
      } else if (props.data.collection === 'institutions') {
        const institutionStore = useInstitutionStore()
        await callOnce('institution-init', institutionStore.initInstitutions)
        items = institutionStore.payload
      } else if (props.data.collection === 'demo_searches') {
        const searchStore = useSearchStore()
        await callOnce('demo-searches-init', searchStore.initDemoSearches)
        items = searchStore._demoSearches
      } else if (props.data.collection === 'content') {
        // TODO: yet to be implemented, fetch items from content
      }

      // Restructure the items to have the same format for both types
      items = items.map((item) => {
        return {
          collection: props.data.collection,
          item: item,
        }
      })
    }

    // Filter items based on the dataEnv (if it is present) and date_published and date_unpublished
    items = items.filter((item) => {
      return (
        (item.item?.envs === undefined ||
          item.item.envs.includes(config.public.dataEnv)) &&
        checkDateRange(item.item.date_published, item.item.date_unpublished)
      )
    })

    // Convert the items to a format that the BlockSlider or BlockLinks component can render
    if (props.data.type === 'links' && items.length > 0) {
      return convertToToBlockLinks(items)
    } else if (props.data.type === 'slider' && items.length > 0) {
      return convertToToBlockSlider(items)
    }
  }
)

// The incoming data is a collection of items, each with a collection key
// depending on the collection key, we need to convert the item to a different format
// so that the BlockSliderItem component can render it
function convertToToBlockSlider(items) {
  return {
    type: 'standard',
    aspect_ratio: props.data.aspect_ratio || '3/1',
    row_mode: props.data.row_mode,
    id: props.data.id,
    items: items
      .flatMap((item) => {
        const localized = getCurrentLocale(item.item.translations)
        // possible collections: institutions, content, demo_searches
        if (item.collection === 'institutions') {
          const color = nextColor()
          return [
            {
              type: item.item.hero_image ? 'image-text' : 'text',
              id: localized.slug,
              color_text: color.color_text,
              color_background: color.color_background,
              heading: localized.title,
              collection: item.collection,
              summary: localized.summary,
              image: item.item.hero_image,
              link: localePath('institutions') + '/' + localized.slug,
              is_long_heading: localized.title.length > 30,
            },
          ]
        } else if (item.collection === 'content') {
          const localized = getCurrentLocale(item.item.translations)
          const localizedCategory = getCurrentLocale(
            item.item.category?.translations
          )
          return [
            {
              type: 'image-text',

              category: localizedCategory.title,
              heading: localized.title,
              collection: item.collection,
              summary: localized.summary,
              image: item.item.seo?.og_image || item.item.image,
              color_text: item.item.color_text || props.data.color_text,
              color_background:
                item.item.color_background || props.data.color_background,
              link: localePath('content') + '/' + localized.slug,
            },
          ]
        } else if (item.collection === 'demo_searches') {
          const localized = getCurrentLocale(item.item.translations)
          return [
            {
              type: 'image-text',
              id: localized.slug,
              category: t('block.demo_searches'),
              heading: localized.name,
              summary: localized.summary,
              image: item.item.image,
              color_text: item.item.color_text || props.data.color_text,
              color_background:
                item.item.color_background || props.data.color_background,
              link: localePath('search') + '/' + item.item.url,
            },
          ]
        } else if (item.collection === 'news') {
          // TODO: yet to be implemented, transform items from news
        }
        return []
      })
      .pickItemsAndRandomize(props.data.pick_items, props.data.randomize),
  }
}

// The incoming data is a collection of items, each with a collection key
// depending on the collection key, we need to convert the item to a different format
// so that the BlockLinks component can render it
function convertToToBlockLinks(items) {
  return {
    type: 'standard',
    heading: props.data.heading,
    summary: props.data.summary,
    id: props.data.id,
    items: items
      .flatMap((item) => {
        const localized = getCurrentLocale(item.item.translations)
        if (item.collection === 'institutions') {
          return [
            {
              heading: localized.title,
              summary: localized.summary,
              link: localePath('institutions') + '/' + localized.slug,
            },
          ]
        } else if (item.collection === 'content') {
          const localized = getCurrentLocale(item.item.translations)
          return [
            {
              heading: localized.title,
              summary: localized.summary,
              link: localePath('content') + '/' + localized.slug,
            },
          ]
        } else if (item.collection === 'demo_searches') {
          const localized = getCurrentLocale(item.item.translations)
          return [
            {
              heading: localized.name,
              summary: localized.summary,
              link: localePath('search') + '/' + item.item.url,
            },
          ]
        } else if (item.collection === 'news') {
          const localized = getCurrentLocale(item.item.translations, false)
          if (localized !== undefined) {
            return [
              {
                heading: localized.heading,
                summary: localized.summary,
                call_to_action: localized.call_to_action,
                link: item.item.link,
              },
            ]
          }
        }
        return []
      })
      .pickItemsAndRandomize(props.data.pick_items),
  }
}
</script>
<template>
  <section
    v-if="convertedData"
    :class="['block-list', 'block-list-' + data.type]"
  >
    <BlockSlider v-if="data.type === 'slider'" :data="convertedData" />
    <BlockLinks v-if="data.type === 'links'" :data="convertedData" />
  </section>
</template>
